import Vue from 'vue'
import VueRouter from 'vue-router'
// ../layout/index
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
  	// component: Layout,
	component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "about" */ '../views/test.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
